/*jslint browser: true, devel: true */
/*global Momentus, Event, window, mc_ajax_vars, anime */

/**
 * Only log to console if on local machine
 */
var log = function (message) {
    "use strict";
    var debug = true;

    if (window.location.href.indexOf(".test") >= 0 || debug) {
        console.log(message);
    }
};

/**
 * Send a POST request to the server with the specified
 * data and send the response to the passed callback
 *
 * n.b.: uses Wordpress' wp_ajax_{ACTION} filter
 */
var postData = function (data, action, cb) {
    "use strict";

    // log("Submitting data to server");

    var xhr = new XMLHttpRequest();

    xhr.open("POST", mc_ajax_vars.url + "?action=" + action);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(data);

    xhr.addEventListener("load", function () {
        var response = JSON.parse(xhr.responseText);
        cb(response);
    });
};

/**
 * Update texts for origin and destination based on selected values
 */
var updateOrbitText = function (point, request) {
    "use strict";

    // add matching orbit long-form name to visualisation
    // and update the width of the box to match
    var button_text = request[point].orbit_long;
    var button_text_arr = button_text.trim().split(/\s+/);

    // if we're descending, swap the selector used to place the text in box
    if (request.descending) {
        point = point === "origin"
            ? "destination"
            : "origin";
    }

    var matching_text = document.querySelector("#mcv-" + point + " .mcv-text");
    var matching_box = document.querySelector("#mcv-" + point + " .mcv-box");

    // trim text if more than three words
    if (button_text_arr.length >= 4) {
        button_text = "<tspan x=\"0\" dy=\"14.5\">" + button_text_arr.slice(3).join(" ") + "</tspan>";
        button_text = "<tspan>" + button_text_arr.splice(0, 3).join(" ") + "</tspan>" + button_text;
    }

    matching_text.innerHTML = button_text;
    matching_box.setAttribute("width", (matching_text.getBBox().width + 24));
    matching_box.setAttribute("height", (matching_text.getBBox().height + 14));
};

/*
 * Iterate through form building a request from inputs
 */
var buildRequest = function (form) {
    "use strict";

    var request = {
        "launchers": [], // only used by UI elements
        "descending": false, // only used by UI elements
        "includeLaunch": false,
        "initialOrbitMode": null,
        "finalOrbitMode": null,
        "payload": {
            "mass": 0
        },
        "origin": {},
        "destination": {}
    };

    // Loop through each field in the form
    Array.from(form.elements).forEach(function (field) {
        var matches = null;

        // Don't serialize fields without a name, submits,
        // buttons, file and reset inputs, and disabled fields
        if (
            !field.name
            || field.disabled
            || field.type === 'file'
            || field.type === 'reset'
            || field.type === 'submit'
            || field.type === 'button'
            || field.validity.valid === false
            || (field.checked === false && (field.type === "radio" || field.type === "checkbox"))
        ) {
            return;
        }

        // split names of inputs using square brackets
        // e.g. origin[apogee] -> origin.apogee
        matches = field.name.match(/(.*?)\[(.*?)\]/);

        if (matches === null) {
            if (field.name === "payload") {
                request.payload.mass = parseInt(field.value);
            } else {
                // request[field.name] = (field.value);
            }

        } else {
            var point = matches[1];
            var type = matches[2];

            if (type === "launchers") {
                request.launchers.push(field.value);
            } else if (type === "hasLaunchers") {
                request.includeLaunch = true;
            } else if (type === "orbit") {
                // get value from input or active option element
                field = field.selectedIndex
                    ? field[field.selectedIndex]
                    : field;

                // parse meta data related to this input
                var data = JSON.parse(field.getAttribute("data-inputs"));

                request[point === "origin"
                    ? "initialOrbitMode"
                    : "finalOrbitMode"] = Momentus.Mode[data.mode.toUpperCase()];
                request[point][type] = field.value;
                request[point].orbit_long = field.hasAttribute("data-long")
                    ? field.getAttribute("data-long")
                    : field.innerHTML;
            } else {
                request[point][type] = field.value;
            }
        }
    });

    // this value determines the direction of the visualisation
    request.descending = request.origin.hasOwnProperty("perigee")
            && (parseInt(request.origin.perigee) > parseInt(request.destination.perigee));

    return request;
};

var buildTrips = function (request, launchers) {
    "use strict";

    var trips = [];

    // iterate through all launchers matching with any selected launchers
    // and making an appropriate request
    if (request.includeLaunch === true) {
        Momentus.LAUNCHERS.forEach(function (launcher) {
            if (
                launchers.includes(launcher.name)
                && launcher.orbit.toLowerCase().indexOf(request.origin.orbit) >= 0
            ) {
                Momentus.RIDES.forEach(function (ride) {
                    var trip = Momentus.Ride.planTrip(request, ride, launcher);

                    if (trip.valid === true) {
                        trip.ride = ride;
                        trip.launcher = launcher;
                        trips.push(trip);
                    }
                });
            }
        });

    } else {
        Momentus.RIDES.forEach(function (ride) {
            var trip = Momentus.Ride.planTrip(request, ride);
            if (trip.valid === true) {
                trip.ride = ride;
                trips.push(trip);
            }
        });
    }

    return trips;
};

var sortElements = function (list, attribute, desc) {
    "use strict";

    var array_items = [];

    list.forEach(function (item) {
        if (item.nodeType === 1) { // get rid of the whitespace text nodes
            array_items.push(item);
        }
    });

    array_items.sort(function (a, b) {

        var value_a = parseInt(a.getAttribute("data-" + attribute));
        var value_b = parseInt(b.getAttribute("data-" + attribute));

        if (desc) {
            return value_b - value_a;
        } else {
            return value_a - value_b;
        }
    });

    return array_items;
};

document.addEventListener("CalculatorLoaded", function () {
    "use strict";

    log("Calculator loaded.");

    var overlay = document.getElementById("js-overlay");

    // sections
    var section_calculator = document.querySelector(".mc-calculator");
    var section_splash = document.querySelector(".mc-splash");
    var section_view = document.querySelector(".mc-view");

    // sub-sections
    var list_rides = document.getElementById("mc-ride-list");
    var list_launchers = document.getElementById("mc-launcher-list");

    // forms
    var form_orbits = document.getElementById("mc-orbits");
    var form_sort = document.getElementById("mc-sort");

    // orbits
    var options = form_orbits.querySelectorAll(".mc-option");
    var buttons_options = form_orbits.querySelectorAll(".js-option");
    var select_origin = document.querySelector("#origin .js-orbitSelect");
    var select_dest = document.querySelector("#destination .js-orbitSelect");
    var range_payload = document.getElementById("js-range");
    var label_payload = document.getElementById("payload-count");
    var inputs_launchers = document.querySelectorAll(".js-launcherInput"); // populated later when added to DOM
    var radio_launcher_orbits = document.querySelectorAll(".js-launcherRadio");

    // templates used to build empty tickets
    // or launcher inputs
    var template_empty = document.getElementById("empty");
    var template_launcher = document.getElementById("launcher");

    var toggle_view = document.getElementById("js-view");
    var toggle_rides = document.getElementById("js-show-rides");

    var graphic_earth = document.getElementById("mcv-earth-shape");
    var graphic_splashes = section_splash.querySelectorAll("svg > g");

    var trigger_submit = new Event("submit", {cancelable: true});
    var trigger_change = new Event("change");

    var current_request;

    /**
     * First things first: we need to clean up the Launcher
     * array, removing duplicates, then we need to add all
     * valid launchers from the SDK as inputs using the template.
     */

    var addLaunchers = function () {
        var seen_launchers = {};
        var seenIndex = 0;
        var launchers = [];

        /**
         * Remove duplicate Launchers from array while combining
         * orbit from duplicate Launcher to existing launcher… yep.
         */
        Momentus.LAUNCHERS.forEach(function (launcher) {

            launcher.cleanName = launcher.name.replace(/\W+/g, '-').toLowerCase();

            var existingIndex = seen_launchers[launcher.cleanName];

            if (existingIndex) {
                launchers[existingIndex].orbits += (" " + launcher.orbit);
                return false;
            }

            launcher.orbits = launcher.orbit;
            seen_launchers[launcher.cleanName] = seenIndex;
            seenIndex = launchers.push(launcher);
        });

        // add launcher input elements from SDK
        launchers.forEach(function (launcher) {
            var clone = document.importNode(template_launcher.content, true);

            var text = document.createTextNode(launcher.name),
                label = clone.querySelector("label"),
                input = clone.querySelector("input");

            input.setAttribute("data-orbits", launcher.orbits);

            label.appendChild(text);
            label.setAttribute("for", launcher.cleanName);

            input.id = launcher.cleanName;
            input.value = launcher.name;
            list_launchers.appendChild(clone);
        });

        inputs_launchers = document.querySelectorAll(".js-launcherInput");
    };
    addLaunchers();

    /**
     * Toggle overlay visibility. If hiding,
     * remove given class from all matching elements.
     */
    var toggleOverlay = function (target_class) {
        if (section_calculator.classList.toggle("has-overlay")) {
            overlay.setAttribute("data-target", target_class);
        } else {
            target_class = overlay.getAttribute("data-target");
            section_calculator.querySelectorAll("." + target_class).forEach(function (element) {
                element.classList.remove(target_class);
            });
        }
    };
    // hide overlay when clicked
    overlay.addEventListener("click", toggleOverlay);

    /**
     * Attach handlers to tickets after adding via AJAX
     */
    var ride_handlers = function () {

        list_rides.querySelectorAll(".mc-ticket").forEach(function (ticket) {
            var ride_name = document.getElementById("mcv-ride-text");
            var launcher_name = document.getElementById("mcv-launch-name");
            var ticket_form = ticket.querySelector("form");

            ticket_form.addEventListener("submit", function (event) {
                event.preventDefault();

                ticket_form.classList.add("is-loading");

                postData(JSON.stringify({
                    "trip": JSON.parse(ticket.getAttribute("data-trip")),
                    "request": current_request,
                    "client": {
                        email: ticket_form.email.value,
                        title: ticket_form.title.value,
                        note: ticket_form.note.value
                    }
                }), "email", function () {
                    ticket_form.classList.add("has-message");

                    setTimeout(function () {
                        toggleOverlay("has-form");
                        ticket_form.reset();
                        ticket_form.classList.remove("is-loading");
                        ticket_form.classList.remove("has-message");
                    }, 7000);
                });
            });

            ticket.addEventListener("click", function (event) {
                var element = event.target;

                if (element.classList.contains("mc-ticket__open")) {
                    document.querySelectorAll(".is-expanded").forEach(function (ticket_expanded) {
                        ticket_expanded.classList.remove("is-expanded");
                    });
                    section_view.classList.remove("has-ride");
                    section_view.classList.remove("has-launcher");

                    ticket.classList.add("is-expanded");
                    section_view.classList.add("has-ride");
                    ride_name.innerHTML = ticket.getAttribute("data-ride");

                    if (ticket.hasAttribute("data-launcher")) {
                        section_view.classList.add("has-launcher");
                        launcher_name.innerHTML = ticket.getAttribute("data-launcher");
                    }

                } else if (element.classList.contains("mc-ticket__close")) {
                    ticket.classList.remove("is-expanded");
                    section_view.classList.remove("has-ride");
                    section_view.classList.remove("has-launcher");

                } else if (element.classList.contains("mc-ticket__save")) {
                    ticket.classList.add("has-form");
                    toggleOverlay("has-form");

                } else if (element.classList.contains("mc-form__close")) {
                    toggleOverlay("has-form");
                }
            });
        });
    };

    /**
     * Pad value with zeros as determined by input's max value
     */
    var padMassValues = function (number, max) {
        return number > max
            ? max
            : ("00000" + number).slice(-max.length);
    };

    /**
     * Update displayed value as range slider is updated
     */
    range_payload.addEventListener("input", function () {
        label_payload.value = padMassValues(parseInt(range_payload.value), range_payload.max);
    });

    /**
     * Ensure the value doesn't exceed the max range of the input
     */
    label_payload.addEventListener("input", function () {
        var newValue = padMassValues(parseInt(label_payload.value), range_payload.max);
        label_payload.value = newValue;
        range_payload.value = newValue;
    });

    toggle_view.addEventListener("click", function () {
        section_calculator.classList.toggle("has-view");
    });

    toggle_rides.addEventListener("click", function () {
        document.documentElement.classList.remove("is-fixed");
        section_calculator.classList.remove("has-rides");
        section_view.classList.remove("has-ride");
        section_view.classList.remove("has-orbits");
        document.body.classList.remove("mc-has-rides");
        section_calculator.classList.add("has-splash");
        section_view.setAttribute("data-orbit", "splash");
    });

    /**
     * Listen for clicks on splash, select specified
     * orbit, trigger form submission, show selection DIV.
     */
    section_splash.addEventListener("click", function (event) {
        var element = event.target;

        if (element.hasAttribute("data-dest")) {
            var orbit_dest = element.getAttribute("data-dest");

            select_origin.value = "leo";
            select_dest.value = orbit_dest;

            if (orbit_dest === "--") {
                document.querySelector("#destination .js-option").click();
                document.querySelector("#origin .mc-button__value").innerHTML = "LEO";
                document.querySelector("#origin .js-option").classList.add("has-value");
            } else {
                select_dest.dispatchEvent(trigger_change);
            }

            select_origin.dispatchEvent(trigger_change);

            if (orbit_dest === "leo") {
                document.querySelector("#origin input[name=\"origin[perigee]\"]").value = 300;
                document.querySelector("#origin input[name=\"origin[apogee]\"]").value = 300;
            }

            if (orbit_dest !== "--") {
                // trigger events so button, view, etc. updates
                // and the form is submitted, showing rides
                form_orbits.dispatchEvent(trigger_submit);
            }

            document.documentElement.classList.add("is-fixed");
            section_calculator.classList.remove("has-splash");
            section_calculator.classList.add("has-rides");
            graphic_earth.removeAttribute("style");
            document.body.classList.add("mc-has-rides");
        }
    });

    /**
     * Listen for clicks in options, toggle, show and
     * hide modals as needed.
     */
    options.forEach(function (option) {
        option.addEventListener("click", function (event) {
            var target_element = event.target;

            if (
                target_element.classList.contains("js-option")
                || target_element.classList.contains("mc-modal__button")
            ) {
                if (form_orbits.reportValidity() !== false) {
                    option.classList.toggle("has-modal");
                    toggleOverlay("has-modal");
                }
            }
        });
    });

    var toggle_origin = document.getElementById("origin-toggle");
    toggle_origin.addEventListener("click", function () {
        document.getElementById("origin").classList.toggle("is-toggled");
        radio_launcher_orbits.forEach(function (radio) {
            radio.disabled = !toggle_origin.checked;
        });
    });

    /**
     * enable / disable launchers based on orbit type
     */
    radio_launcher_orbits.forEach(function (radio) {
        radio.addEventListener("change", function () {

            inputs_launchers.forEach(function (item) {
                var label = item.querySelector("label");
                var input = item.querySelector("input");
                var orbits = input.getAttribute("data-orbits").toLowerCase();

                // if the orbits data attribute doesn't contain this orbit
                // disable the input and unselect it
                if (orbits.indexOf(radio.value) >= 0) {
                    label.classList.add("is-active");
                    input.disabled = false;
                    input.checked = true;
                } else {
                    label.classList.remove("is-active");
                    input.disabled = true;
                    input.checked = false;
                }
            });
        });
    });

    /**
     * Show / hide inputs based on selected orbit values
     * while updating min / max range and tooltip text
     */
    document.querySelectorAll(".js-orbit-section").forEach(function (section) {
        var orbitSelect = section.querySelector(".js-orbitSelect");

        // show / hide value groups matching selected orbit value
        orbitSelect.addEventListener("change", function () {
            var selectedOption = orbitSelect.options
                ? orbitSelect.options[orbitSelect.selectedIndex]
                : null;

            var selectedInputs = selectedOption
                ? JSON.parse(selectedOption.getAttribute("data-inputs"))
                : null;

            section.querySelectorAll(".js-orbitLabel").forEach(function (label) {
                var input = label.querySelector("input");
                var inputName = input.getAttribute("data-name");
                var range = label.querySelector("small");

                if (selectedInputs.inputs.hasOwnProperty(inputName)) {
                    var selected = selectedInputs.inputs[inputName];
                    label.classList.add("is-active");
                    input.disabled = false;
                    input.value = selected.default;

                    if (selected.range !== null) {
                        input.setAttribute("min", selected.range[0]);
                        input.setAttribute("max", selected.range[1]);
                        range.innerHTML = '(' + selected.range[0] + ' - ' + selected.range[1] + ')';
                    } else {
                        input.setAttribute("min", null);
                        input.setAttribute("max", null);
                        range.innerHTML = '(any)';
                    }

                } else {
                    label.classList.remove("is-active");
                    input.disabled = true;
                    input.value = "";
                    range.innerHTML = '(n/a)';
                }
            });
        });
    });

    if (graphic_splashes) {

        var splash_trips = document.querySelectorAll(".mcv-splash-trip");
        var splash_rides = document.querySelector(".mcv-rides-holder");

        var splash_ardoride = '<svg height="24.04" viewBox="0 0 33.21 24.04" width="33.21" xmlns="http://www.w3.org/2000/svg"><path d="m15.25 9.19v5.65l2.82-2.84zm5.09-8.34-2.26-.85v24l2.26-.84zm12.8 22.28.07-22.27h-11v22.24zm-19.7-11.4s-.87-3.05-3.75-3.08c-3.69.01-9.69 3.35-9.69 3.35s6.08 3.39 9.69 3.46c2.83 0 3.75-3 3.75-3a4.4 4.4 0 0 1 -2.06.78 1.5 1.5 0 0 1 -2.09-.36 1.48 1.48 0 0 1 .17-1.93 1.5 1.5 0 0 1 2 0 4.18 4.18 0 0 1 1.98.78z" fill="#fff"/></svg>';

        var splash_vigoride = '<svg height="24.06" viewBox="0 0 32.58 24.06" width="32.58" xmlns="http://www.w3.org/2000/svg"><path d="m14.31 12.45a4.73 4.73 0 0 1 -2 .84 3.61 3.61 0 0 1 -2.67-1.15 3.63 3.63 0 0 1 2.64-1.3 4.67 4.67 0 0 1 2.09.84s-1-3.24-4-3.29c-3.93.03-10.37 3.72-10.37 3.72s6.44 3.6 10.39 3.58c3-.1 4-3.3 4-3.3zm2-3.4.05 6 3-3zm12.11-9.05a4.2 4.2 0 0 0 -4.27 4.14v.06 15.62a4.22 4.22 0 0 0 8.44 0v-15.61a4.21 4.21 0 0 0 -4.17-4.21zm-9.08.15.07 23.91h2.45l-.08-23.96z" fill="#fff"/></svg>';

        splash_trips.forEach(function (trip, i) {
            var path = anime.path(trip.querySelector(".mcv-splash-traj path:last-of-type"));
            var ride = document.createElement("div");
            var tline = anime.timeline({
                loop: true,
                duration: 2000, // default duration if not specified
                easing: "easeInQuart",
                delay: i * anime.random(2000, 5000)
            });

            ride.classList.add("mcv-ride-icon");
            ride.innerHTML = Math.round(Math.random())
                ? splash_vigoride
                : splash_ardoride;

            ride = splash_rides.appendChild(ride);

            tline
                .add({ // animating ride along path
                    targets: ride,
                    opacity: [
                        {value: 0, duration: 2000},
                        {value: 1, duration: 500},
                        {value: 1, duration: 2500},
                        {value: 0, duration: 500}
                    ],
                    translateX: path('x'),
                    translateY: path('y'),
                    rotate: path('angle'),
                    easing: "easeInSine",
                    duration: 6000
                }, 0)

                .add({ // first horzontal line
                    targets: trip.querySelector(".mcv-splash-l1"),
                    keyframes: [
                        {strokeDashoffset: 2000, duration: 2000},
                        {strokeDashoffset: 2000, duration: 3000},
                        {strokeDashoffset: 1000, duration: 1000}
                    ],
                    easing: "easeInOutSine"
                }, 250)

                .add({ // first square
                    targets: trip.querySelector(".mcv-splash-sq1"),
                    keyframes: [
                        {opacity: 1, duration: 500},
                        {opacity: 1, duration: 3500},
                        {opacity: 0, duration: 1000}
                    ]
                }, 600)

                .add({ // second horzontal line
                    targets: trip.querySelector(".mcv-splash-l2"),
                    keyframes: [
                        {strokeDashoffset: 2000, duration: 2000},
                        {strokeDashoffset: 2000, duration: 2500},
                        {strokeDashoffset: 1000, duration: 1000}
                    ],
                    easing: "easeInOutSine"
                }, 1000)

                .add({ // second square
                    targets: trip.querySelector(".mcv-splash-sq2"),
                    keyframes: [
                        {opacity: 1, duration: 500},
                        {opacity: 1, duration: 3000},
                        {opacity: 0, duration: 1000}
                    ]
                }, 1750)

                .add({ // trajectory
                    targets: trip.querySelectorAll(".mcv-splash-traj"),
                    keyframes: [
                        {strokeDashoffset: 2000, duration: 2000},
                        {strokeDashoffset: 2000, duration: 3000},
                        {strokeDashoffset: 1000, duration: 1000}
                    ],
                    easing: "easeInExpo"
                }, 250);
        });
    }

    /**
     * Detect any changes to the sort form, submit form
     */
    form_sort.querySelectorAll("input, select").forEach(function (input) {
        input.addEventListener("change", function () {
            form_sort.dispatchEvent(trigger_submit);
        });
    });

    /**
     * Sort all visible tickets, empty ticket holder,
     * add re-sorted tickets back one by one.
     */
    form_sort.addEventListener("submit", function (event) {
        event.preventDefault();

        var desc = (form_sort.direction.value === "desc");
        var filter = form_sort.sort.value;
        var tickets = list_rides.querySelectorAll(".mc-ticket");

        var sorted = sortElements(tickets, filter, desc);

        list_rides.innerHTML = "";

        sorted.forEach(function (ticket) {
            list_rides.appendChild(ticket);
        });
    });

    /**
     * Listen for option form submission
     */
    form_orbits.addEventListener("submit", function (event) {
        event.preventDefault();

        var trip_array = [];

        // add remove needed classes
        section_calculator.classList.add("is-loading");
        section_view.classList.remove("has-ride");
        section_view.classList.remove("has-launcher");

        // empty the ride DIV before repopulating it
        list_rides.innerHTML = "";

        // loop over form elements and build the request
        current_request = buildRequest(form_orbits);

        // apply selected orbits to buttons
        buttons_options.forEach(function (button) {
            var button_target = button.getAttribute("data-target");
            var inner_text = button_target === "payload"
                ? current_request.payload.mass + " kg"
                : current_request[button_target].orbit.toUpperCase();

            button.querySelector(".mc-button__value").innerHTML = inner_text;
            button.classList.add("has-value");

            if (button_target !== "payload") {
                updateOrbitText(button_target, current_request);
            }
        });

        var view_orbit = current_request.descending
            ? current_request.origin.orbit
            : current_request.destination.orbit;

        // set the destination orbit to the view element
        switch (view_orbit) {
        case "llo":
        case "lto":
            view_orbit = "lunar";
            break;
        case "nea":
        case "mtt":
            view_orbit = "escape";
            break;
        case "iss":
        case "mol":
            view_orbit = "leo";
            break;
        case "gto":
        case "geo":
            view_orbit = "heo";
            break;
        default:
            break;
        }

        section_view.setAttribute("data-orbit", view_orbit);
        trip_array = buildTrips(current_request, current_request.launchers);

        if (trip_array.length <= 0 || (
            current_request.includeLaunch &&
            current_request.launchers.length <= 0
        )) {
            log("No valid trips found");

            list_rides.appendChild(document.importNode(template_empty.content, true));
            section_calculator.classList.remove("is-loading");
            return;
        }

        // make async request to server, adding rides
        // when response reveived from server.
        postData(JSON.stringify({
            "trips": trip_array,
            "request": current_request
        }), "calculate", function (response) {
            section_view.classList.toggle("is-descending", current_request.descending);
            section_view.classList.add("has-orbits");
            list_rides.innerHTML = response.output;
            section_calculator.classList.remove("is-loading");
            ride_handlers();
        });
    });
});